import React from "react";
import success from "../../../../assets/images/success.png";
import successCenter from "../../../../assets/images/success2.png";
import "./response.scss";
import { Link } from "react-router-dom";
const Success = () => {
  return (
    <div className="success-contaier w-full h-full mt-10 ">
      <div className="flex justify-center">
        <img src={success} alt="" />
      </div>
      <div className=" w-[477px] h-auto mx-auto py-8">
        <div className="flex justify-center">
          <img src={successCenter} alt="" />
        </div>
        <div className=" mt-[-80px]">
          <h1 className="text-[32px] text-center mb-4 font-extrabold text-[#0E2D60]">
            تمت العملية بنجاح
          </h1>
          <Link to={"/"}>
            <button className="btn-success">العودة للصفحة الرئيسية</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Success;
