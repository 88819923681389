import React from "react";
import axios from "axios";
import config from "../Services/config";

const ApplePayButton = () => {
  const baseUrl = config.baseUrl;

  const authorizeMerchant = async (validationURL) => {
    try {
      const response = await axios.post(`${baseUrl}/validate-merchant`, {
        validation_url: validationURL,
      });

      // Assuming the response contains the required merchant session
      const merchantSession = response.data;
      console.log("Merchant session:", merchantSession);
      return merchantSession;
    } catch (error) {
      console.error("Error validating merchant:", error);
      throw new Error("Merchant validation failed");
    }
  };

  const initiateApplePay = async () => {
    console.log("Initiating Apple Pay...");

    if (window.PaymentRequest) {
      const methodData = [
        {
          supportedMethods: "https://apple.com/apple-pay",
          data: {
            version: 3, // Apple Pay API version
            merchantIdentifier: "merchant.com.mohmsa.mohm", // Your Merchant ID
            merchantCapabilities: [
              "supports3DS",
              "supportsCredit",
              "supportsDebit",
            ],
            supportedNetworks: ["visa", "masterCard", "amex"], // Payment networks you support
            countryCode: "SA", // Your country code
            currencyCode: "SAR", // Your currency code
          },
        },
      ];

      const details = {
        total: {
          label: "MohmMerchant",
          amount: {
            currency: "SAR",
            value: "1.15",
          },
        },
      };

      let paymentResponse;

      try {
        const request = new PaymentRequest(methodData, details);
        console.log("request", request);

        request.onmerchantvalidation = async (event) => {
          const validationURL = event.validationURL;
          console.log("Merchant Validation URL:", validationURL);

          try {
            const merchantSession = await authorizeMerchant(validationURL);
            event.complete(merchantSession);
          } catch (error) {
            console.error("Merchant validation failed:", error);
            event.complete(null);
          }
        };

        const canMakePayment = await request.canMakePayment();
        console.log("canMakePayment", canMakePayment);
        if (canMakePayment) {
          paymentResponse = await request.show();
          console.log("paymentResponse", paymentResponse);

          await paymentResponse.complete("success");
          console.log("Payment completed successfully");
        } else {
          console.log("User cannot make payments with Apple Pay");
        }
      } catch (error) {
        console.error("Payment processing error:", error.message || error);

        if (paymentResponse) {
          await paymentResponse.complete("fail");
        }
      }
    } else {
      console.log("Payment Request API is not supported in this browser.");
    }
  };

  return (
    <button
      className="w-full mt-4 mx-2 payment-button text-[24px]"
      id="apple-pay-button"
      style={{
        WebkitAppearance: "-apple-pay-button",
        height: "64px",
        backgroundImage:
          "-webkit-named-image(apple-pay-with-text-white, apple-pay-with-text-black)",
        backgroundSize: "100% 60%",
        backgroundOrigin: "content-box",
        backgroundRepeat: "no-repeat",
        padding: "10px",
        width: "100%",
      }}
      onClick={initiateApplePay}
    ></button>
  );
};

export default ApplePayButton;
