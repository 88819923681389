import React from "react";

const ApplePayButton = () => {
  // Mock response structure for merchant session
  // const mockMerchantSession = {
  //   merchantIdentifier: "merchant.com.mohmsa.mohm",
  //   displayName: "Mohm App",
  //   signature: "your-signature", // Replace with a valid signature if needed
  //   epochTimestamp: Math.floor(Date.now() / 1000),
  //   expirationTime: Math.floor(Date.now() / 1000) + 3600, // 1 hour expiration
  // };
  const mockMerchantSession = {
    provider: "apple_pay",
    data: {
      merchantIdentifier: "merchant.com.mohmsa.mohm",
      displayName: "Mohm App",
      signature: "your-signature", // Replace with a valid signature if needed
      merchantCapabilities: ["3DS", "debit", "credit"],
      supportedNetworks: ["amex", "visa", "discover", "masterCard"],
      countryCode: "SA",
      currencyCode: "SAR",
      epochTimestamp: Math.floor(Date.now() / 1000),
      expirationTime: Math.floor(Date.now() / 1000) + 3600, // 1 hour expiration
      requiredBillingContactFields: null,
      requiredShippingContactFields: null,
      shippingMethods: [
        {
          amount: "1.15",
          detail: "Available within an hour",
          identifier: "in_store_pickup",
          label: "In-Store Pickup",
        },
      ],
    },
  };

  const authorizeMerchant = async () => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(mockMerchantSession), 1000); // Simulate network delay
    });
  };

  //   const initiateApplePay = async () => {
  //     console.log("Initiating Apple Pay...");

  //     if (window.PaymentRequest) {
  //       alert(1)
  //       const methodData = [
  //         {
  //           supportedMethods: "https://apple.com/apple-pay",
  //           data: {
  //             version: 3, // Apple Pay API version
  //             merchantIdentifier: "merchant.com.mohmsa.mohm", // Your Merchant ID
  //             merchantCapabilities: [
  //               "supports3DS",
  //               "supportsCredit",
  //               "supportsDebit",
  //             ],
  //             supportedNetworks: ["visa", "masterCard", "amex"], // Payment networks you support
  //             countryCode: "SA", // Your country code
  //             currencyCode: "SAR", // Your currency code
  //           },
  //         },
  //       ];

  //       const details = {
  //         total: {
  //           label: "MohmMerchant",
  //           amount: {
  //             currency: "SAR",
  //             value: "10.00",
  //           },
  //         },
  //       };

  //       let paymentResponse;

  //       try {
  //         const request = new PaymentRequest(methodData, details);
  // console.log("request",request);
  //         const canMakePayment = await request.canMakePayment();

  //         if (canMakePayment) {
  //           paymentResponse = await request.show();
  // console.log("paymentResponse",paymentResponse);
  //           paymentResponse.onmerchantvalidation = async (event) => {
  //             const merchantSessionPromise = authorizeMerchant();
  //             event.complete(merchantSessionPromise);
  //           };

  //           await paymentResponse.complete("success");
  //           console.log("Payment completed successfully");
  //         } else {
  //           console.log("User cannot make payments with Apple Pay");
  //         }
  //       } catch (error) {
  //         console.error("Payment processing error:", error.message || error);

  //         if (paymentResponse) {
  //           await paymentResponse.complete("fail");
  //         }
  //       }
  //     } else {
  //       console.log("Payment Request API is not supported in this browser.");
  //     }
  //   };
  const initiateApplePay = async () => {
    console.log("Initiating Apple Pay...");

    if (window.PaymentRequest) {
      alert(1);
      const methodData = [
        {
          supportedMethods: "https://apple.com/apple-pay",
          data: {
            version: 3, // Apple Pay API version
            merchantIdentifier: "merchant.com.mohmsa.mohm", // Your Merchant ID
            merchantCapabilities: [
              "supports3DS",
              "supportsCredit",
              "supportsDebit",
            ],
            supportedNetworks: ["visa", "masterCard", "amex"], // Payment networks you support
            countryCode: "SA", // Your country code
            currencyCode: "SAR", // Your currency code
          },
        },
      ];

      const details = {
        total: {
          label: "MohmMerchant",
          amount: {
            currency: "SAR",
            value: "1.15",
          },
        },
      };

      let paymentResponse;

      try {
        const request = new PaymentRequest(methodData, details);
        console.log("request", request);

        // Assign the onmerchantvalidation event handler before calling request.show()
        request.onpaymentmethodchange = (event) => {
          event.updateWith({});
        };

        // @ts-ignore
        request.onshippingaddresschange = (event) => {
          event.updateWith({});
        };

        request.onmerchantvalidation = async (event) => {
          const merchantSession = await authorizeMerchant();
          console.log("merchantSession", merchantSession);
          event.complete(merchantSession);
        };
        console.log(request.onmerchantvalidation);
        const canMakePayment = await request.canMakePayment();
        console.log("canMakePayment", canMakePayment);
        if (canMakePayment) {
          paymentResponse = await request.show();
          console.log("paymentResponse", paymentResponse);

          await paymentResponse.complete("success");
          console.log("Payment completed successfully");
        } else {
          console.log("User cannot make payments with Apple Pay");
        }
      } catch (error) {
        console.error("Payment processing error:", error.message || error);

        if (paymentResponse) {
          await paymentResponse.complete("fail");
        }
      }
    } else {
      console.log("Payment Request API is not supported in this browser.");
    }
  };
  return (
    <button
      className="w-full mt-4 mx-2 payment-button text-[24px]"
      id="apple-pay-button"
      style={{
        WebkitAppearance: "-apple-pay-button",
        height: "64px",
        backgroundImage:
          "-webkit-named-image(apple-pay-with-text-white, apple-pay-with-text-black)",
        backgroundSize: "100% 60%",
        backgroundOrigin: "content-box",
        backgroundRepeat: "no-repeat",
        padding: "10px",
        width: "100%",
      }}
      onClick={initiateApplePay}
    ></button>
  );
};

export default ApplePayButton;
