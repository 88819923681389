import React, { useState, useEffect, startTransition } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
import { IoEyeOffOutline } from "react-icons/io5";
import heading from "../../../assets/images/heading.png";
import "./Login.scss";
// import "./Login.scss";
import axios from "axios";
import { useUser } from "../../context/UserContext";
import { useAuth } from "../../context/AuthContext";
// import { GoogleLogin } from "react-google-login";
import config from "../../Services/config";
import { toast } from "react-toastify";
// import { GoogleLogin } from "@react-oauth/google";
import { useGoogleLogin } from "@react-oauth/google";
import { gapi } from "gapi-script";
import googleImg from "../../../assets/images/google (2).svg";

const redirectUrl =
  new URLSearchParams(window.location.search).get("redirect") || "/";
const clientId =
  "1006340177913-elds8b96llt0c1sr0bkv6fdeaungc0u4.apps.googleusercontent.com";
// console.log(redirectUrl);
const apiUrl = config.apiUrl;
const LoginForm = () => {
  const navigate = useNavigate();
  const { setLoggedInUser } = useUser();
  const { login } = useAuth();
  const [Loading, setLoading] = useState(false);
  //google login
  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      // console.log(tokenResponse);
      const token = tokenResponse.access_token;
      try {
        // Use the access token to get user info from Google's API
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Extract the user's email and name from the response
        const { email, name, picture } = res.data;

        axios
          .post(
            `${apiUrl}/auth/social-login`,
            {
              token: token,
              name: name,
              email: email,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                lang: "ar",
              },
            }
          )
          .then((response) => {
            // Handle the response
            toast.success(response.data.message, { autoClose: false });

            const token = response?.data?.body?.token;
            const data = response?.data?.body;

            // console.log("token:", token);
            // console.log("data:", data);
            setLoggedInUser(data);

            login(token);
            setLoggedInUser(data);

            navigate(redirectUrl, { replace: true });
          })
          .catch((error) => {
            // Handle any errors
            console.error("Error:", error);
          });
        // You can now send this information to your backend, store it in state, etc.
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    },
  });

  const handleRoute = () => {
    startTransition(() => {
      navigate("/register", { replace: true });
    });
  };
  //useform
  const form = useForm();
  const {
    register,

    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = form;
  //handel submit
  //on submit form
  const onsubmit = async (data) => {
    // console.log("form submitted", data);
    const baseUrl = config.apiUrl;
    const ApiUrl = `${baseUrl}/auth/login`;
    setLoading(true);
    try {
      const response = await axios.post(ApiUrl, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          lang: "ar",
        },
      });
      // console.log(response);
      if (response?.data && response?.data?.body?.token) {
        // Authentication successful, navigate to the home page or any other route
        toast.success(response.data.message, { autoClose: false });

        // setLoggedInUser(response?.data?.body);
        const data = response?.data?.body;
        const token = response?.data?.body?.token;
        login(token);
        setLoggedInUser(data);

        setTimeout(() => {
          // Navigate to the verify component
          reset();
          // console.log(window.location.href);
          navigate(redirectUrl, { replace: true });
        }, 2000);
      } else {
        // Authentication failed, handle the error (e.g., display an error message)
        toast.error("حدث خطا! تأكد من البريد الالكتروني  أو كلمة السر");
      }
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: true });
      reset();
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: "email profile",
      });
    }
    gapi.load("client:auth2", start);
  }, []);
  const [showPassword, setShowPassword] = useState(false);
  // const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="lg:w-[565px]  login-form-container pt-[100px] z-10 xl:w-[700px] mx-auto">
      <h2 className="text-3xl font-bold mb-16   text-center">
        تسجيل دخول
        <div className="w-[100px] ">
          <img
            src={heading}
            className=" mr-[200px] mt-[-65px] "
            alt="heading"
          />
        </div>
      </h2>
      <div className=" py-2 font-medium sign-in text-[#435469] bg-white rounded-full mb-6">
        <button
          className="flex w-full justify-center "
          onClick={() => loginGoogle()}
        >
          <img className="ml-2" src={googleImg} alt="" />
          تسجيل الدخول بال Gmail
        </button>
      </div>
      <div className="lg:hidden flex items-center  font-bold text-gray-400    ">
        <div className="flex-1 border-t border-gray-300"></div>
        <div className="mx-4"> أو سجل الدخول بالبريد الالكتروني </div>
        <div className="flex-1 border-t border-gray-300"></div>
      </div>
      <div className=" hidden lg:flex items-center  font-bold text-gray-400  px-[130px] my-4">
        <div className="flex-1 border-t border-gray-300"></div>
        <div className="mx-4"> أو سجل الدخول بالبريد الالكتروني </div>
        <div className="flex-1 border-t border-gray-300"></div>
      </div>
      <form
        className="login-form"
        action=""
        noValidate
        onSubmit={handleSubmit(onsubmit)}
      >
        <div className="mb-8">
          <label htmlFor="email" className="block text-sm mb-2"></label>
          <input
            type="email"
            id="email"
            className="w-full name-shadow border p-2 rounded input-login ltr"
            placeholder="البريد الالكتروني  *"
            {...register("email", {
              required: "يجب كتابة البريد الالكتروني .",
            })}
          />
          {errors?.email && (
            <p className="text-red-500">{errors?.email?.message}</p>
          )}
        </div>
        <div className="mb-8   ">
          <div className="relative  ">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              className=" border  w-full p-2 rounded input-login ltr"
              placeholder=" كلمة المرور *"
              {...register("password", {
                required: " يجب كتابة كلمة المرور.",
              })}
            />
            {errors?.email && (
              <p className="text-red-500">{errors?.password?.message}</p>
            )}
            <span
              className="absolute top-1/2 left-2 text-gray-400 transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
            </span>
          </div>
        </div>
        <p className=" mb-4 font-semibold text-[14px] text-[#435469]">
          نسيت كلمة المرور ؟{" "}
          <Link to="/forgot-password" className="secondary font-bold ">
            تغيير كلمة المرور
          </Link>
        </p>

        <button
          disabled={!isValid || Loading}
          type="submit"
          className=" submit-btn w-full py-4 px-6  "
        >
          {Loading ? "جاري تسجيل الدخول..." : "تسجيل الدخول"}
        </button>
        <div className="flex mb-8 w-full">
          <div className="w-[300px] text-[#435469] text-base">
            ليس لديك حساب بعد ؟{" "}
            <button
              onClick={handleRoute}
              className=" text-[#43B0C6] font-bold text-base "
            >
              إنشاء حساب
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
