import React, { useEffect, useState } from "react";
import payment from "../../../../assets/images/payment.png";
import shape from "../../../../assets/images/shape.svg";
import methods from "../../../../assets/images/paymentMethods.png";
import heading from "../../../../assets/images/heading.png";
import tick from "../../../../assets/images/tick.svg";
import { useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import config from "../../../Services/config";
import Cookies from "js-cookie";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import {withRouter} from "react-router-dom"
import "./payment.scss";
import ApplePayButton from "./ApplePayButton";

const Payment = () => {
  const token = Cookies.get("authToken");
  const { fetchData, data } = useApi();
  // console.log(data);

  //check the broswer
  const [isSafari, setIsSafari] = useState(false);

  // Function to detect if the browser is Safari
  const detectSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    return (
      userAgent.includes("safari") &&
      !userAgent.includes("chrome") &&
      !userAgent.includes("crios")
    );
  };

  useEffect(() => {
    // Check if the browser is Safari when the component mounts
    setIsSafari(detectSafari());
  }, []);
  const price = data?.body?.annual_fee;
  const PayemntData = {
    amount: price + data?.body?.tax,
    currency: "SAR",
    card_type_id: data?.body?.id,
  };
  // const PaymentAppleData = {
  //   amount: price + data?.body?.tax,
  //   currency: "SAR",
  //   card_type_id: data?.body?.id,
  //   is_apple_pay: 1,
  //   token: "",
  // };
  const { cardId } = useParams();
  const FeaturesDetalis = data?.body;
  // console.log(FeaturesDetalis?.new_price);
  useEffect(() => {
    const baseUrl = config.apiUrl;
    const apiUrl = `${baseUrl}/card-types/${cardId}`;
    window.scrollTo(0, 0);
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      lang: "ar",
    };
    const options = {
      headers: headers,
    };
    fetchData(apiUrl, options);
  }, [cardId]);
  // payment button
  const handlePaymentOnclick = async () => {
    try {
      const baseUrl = config.apiUrl;

      const response = await axios
        .post(`${baseUrl}/cards/order`, PayemntData, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
            lang: "ar",
          },
        })
        .then((response) => {
          if (response?.data?.success) {
            // const paymentUrl = response?.data?.body?.payment_url;
            window.location.href = response.data?.body?.payment_url;
          }

          // console.log(response?.data?.success);
        });
    } catch (error) {
      console.error(" error:", error);
    }
  };
  // const handleApplePaymentOnclick = async () => {
  //   try {
  //     const baseUrl = config.apiUrl;

  //     const response = await axios
  //       .post(`${baseUrl}/cards/order`, PaymentAppleData, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //           lang: "ar",
  //         },
  //       })
  //       .then((response) => {
  //         if (response?.data?.success) {
  //           const paymentUrl = response?.data?.body?.payment_url;
  //           window.location.href = response.data?.body?.payment_url;
  //         }

  //         // console.log(response?.data?.success);
  //       });
  //   } catch (error) {
  //     console.error(" error:", error);
  //   }
  // };
  // const taxWithfee = FeaturesDetalis?.annual_fee;
  // const tax = (taxWithfee * 15) / 100;
  // console.log(tax, taxWithfee);
  return (
    <div className="payment">
      <div className="flex justify-center mt-[150px] mb-[100px] ">
        {" "}
        <img src={payment} alt="" />
      </div>
      <div className="payment-section mb-[100px] mx-auto w-[370] sm:w-[600px] md:w-[700px] lg:w-[1000px] xl:w-[1200px] md:flex justify-center ">
        <div className="order-detalis-container relative ml-4 xl:ml-[50px]">
          <h1 className=" text-[25px] lg:text-[40px] font-extrabold text-[#0E2D60]">
            <img className="absolute  top-[-35px]  " src={heading} alt="" />
            تفاصيل الطلب
          </h1>
          <h3 className="mb-[40px] text-[12px] lg:text-[16px] text-[#395185] font-bold">
            طلب بطاقة مهم الحصرية
          </h3>
          <div className="order-detalis mb-[55px] lg:mb-0 grid grid-cols-2 lg:w-[652px]  xl:w-[700px] h-[404px]">
            <div>
              <h1 className="flex mb-6 text-[16px] font-bold text-[#395185]">
                <img className="ml-2" src={shape} alt="" />
                بطاقة مهم الحصرية
              </h1>
              <ul className="mr-4 text-[#2E2E2E]">
                <li>. {FeaturesDetalis?.description}</li>
              </ul>
            </div>
            <div>
              <h1 className="flex text-[16px] mb-6  font-bold text-[#395185]">
                <img className="ml-2" src={shape} alt="" />
                مميزات البطاقة
              </h1>
              <ul className="  text-[#2E2E2E]">
                <li className="flex">
                  {" "}
                  <img className="ml-2" src={tick} alt="" />
                  {FeaturesDetalis?.features}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="paid relative">
          <h1 className="text-[40px] mb-5 font-extrabold text-[#0E2D60]">
            <img className="absolute  top-[-35px]  " src={heading} alt="" />
            المدفوعات
          </h1>
          <div className=" xl:w-[470px] lg:w-[319px] w-[300px]  ">
            <div className="paid-detlis grid-rows-4 grid-flow-col h-auto  gap-4 px-4 py-4 ">
              <div className="grid grid-cols-3 table-heading pb-4  text-[16px] mb-4 text-[#395185] font-bold">
                <p className="col-span-2">اسم المنتج</p>

                <p> السعر</p>
              </div>
              <div className="grid grid-cols-3 h-[80px] col-span-2 text-[#435469]   text-[16px]">
                <p className="col-span-2"> {FeaturesDetalis?.name} </p>

                <p className="mr-[10px] ">
                  {FeaturesDetalis?.new_price ? (
                    <>
                      <span className="text-[14px]  text-gray  line-through">
                        {FeaturesDetalis?.annual_fee}
                      </span>
                      <span className="mr-[4px] text-[16px] inline-block font-bold  ">
                        {FeaturesDetalis?.new_price}
                      </span>
                    </>
                  ) : (
                    <span className=" text-[15px]   ">
                      {FeaturesDetalis?.annual_fee}
                    </span>
                  )}

                  <span className="ml-1 mt-1"> ريـال سعودي</span>
                </p>
              </div>
              <div className="grid grid-cols-3 h-[80px] text-[#435469]   text-[16px]">
                <p className="col-span-2"> ضريبة القيمة المضافة (15%) </p>

                <p className="mr-[10px] ">
                  {FeaturesDetalis?.tax}
                  <span className="ml-1 mt-1"> ريـال سعودي</span>
                </p>
              </div>
              <div className="grid grid-cols-2 last-row pt-4">
                <div className=" ">
                  <p className="font-bold text-[22px]  text-[#395185]">
                    إجمالي الطلب:
                  </p>
                </div>
                <div className=" ">
                  <p className="font-bold text-[22px] text-[#395185]">
                    {FeaturesDetalis?.annual_fee + FeaturesDetalis?.tax}
                    <span className="ml-1 mt-1"> ريـال </span>
                  </p>
                </div>
              </div>
              <div className="grid grid-cols-2 last-row pt-4">
                <div className=" ">
                  <p className="font-bold text-[22px]  text-[#395185]">
                    الرقم الضريبي:
                  </p>
                </div>
                <div className=" ">
                  <p className="font-bold text-[22px] text-[#395185]">
                    <span className="ml-1 mt-1"> 302213955800003 </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="py-2 mt-2">
              <img src={methods} alt="" />
              <div className="flex">
                <button
                  onClick={handlePaymentOnclick}
                  className="w-full mt-4 mx-2 payment-button"
                >
                  دفع
                </button>
                {isSafari ? (
                  <ApplePayButton className="w-full mt-4 mx-2" />
                ) : (
                  ""
                )}
                {/* <button
                  onClick={handleApplePaymentOnclick}
                  className="w-full mt-4 mx-2 payment-button-apple"
                >
                  Pay
                  <FaApple />
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
