import React from "react";
import success from "../../../../assets/images/success.png";
import successCenter from "../../../../assets/images/failed.png";
import "./response.scss";
import { Link } from "react-router-dom";
const Fail = () => {
  return (
    <div className="success-contaier w-full h-full mt-10 ">
      <div className="flex justify-center">
        <img src={success} alt="" />
      </div>
      <div className=" w-[477px] h-auto mx-auto py-8">
        <div className="flex justify-center">
          <img src={successCenter} alt="" />
        </div>
        <div className=" ">
          <h1 className="text-[32px] text-center my-8 font-extrabold text-[#FF004F]">
            حاول مرة أخرى
          </h1>
          <Link to={"/"}>
            <button className="btn-success">العوده للصفحه الرئيسية</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Fail;
