import React, { useState, useEffect } from "react";
import "./App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";
import config from "./components/Services/config";
import Test from "./components/test/Test";
import Verify from "./components/Pages/Membership/Verify";
import Verified from "./components/Pages/Verified/Verified";
import NotFound from "./components/Pages/MembershipNotFound/NotFound";
import RedirectMohm from "./components/Services/RedirectMohm";
import Orders from "./components/Pages/myOrders/Orders";
import Payment from "./components/Pages/myOrders/payment/Payment";
import { RequestCard } from "./components/Pages/myOrders/RequestCard";
import Success from "./components/Pages/myOrders/response/Success";
import Fail from "./components/Pages/myOrders/response/Fail";
import CouponSuccess from "./components/Pages/CouponsAndOffers/detalis/CouponSuccess";
import DiscountOrderSuccess from "./components/Pages/CouponsAndOffers/tabs/offers/DiscountOrderSuccess";
import "animate.css";
import Navbar from "./components/Navbar/NavBar";
import Footer from "./components/Footer/Footer";
import { HashLoader } from "react-spinners";
import useApi from "./components/hooks/useApi";
import All from "./components/FAQ/All.js";
import Terms from "./components/Pages/Registration/Terms.js";

const VerifyCode = React.lazy(() =>
  import("./components/Pages/Verfiy/VerifyCode")
);
const SuccessVerify = React.lazy(() =>
  import("./components/Pages/Verfiy/success.js")
);
const VerifyCodeMail = React.lazy(() =>
  import("./components/Pages/Verfiy/VerifyCodeMail")
);
const CouponsDetalis = React.lazy(() =>
  import("./components/Pages/CouponsAndOffers/detalis/CouponsDetalis")
);
const AccountCoupon = React.lazy(() =>
  import("./components/myAccount/couponDetalis/AccountCoupon")
);
const Join = React.lazy(() => import("./components/Pages/JoinMedNetwork/Join"));
const Forget = React.lazy(() =>
  import("./components/Pages/Login/ForgetPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/Pages/Login/ResetPassword")
);
const SuccessLogin = React.lazy(() =>
  import("./components/Pages/Login/Success")
);
const MedicalDetails = React.lazy(() =>
  import("./components/Pages/MedicalNetwork/MedicalDetalis/MedicalDetails")
);
const MedicalNetwork = React.lazy(() =>
  import("./components/Pages/MedicalNetwork/MedicalNetwork")
);
const Home = React.lazy(() => import("./components/Home/Home"));
const Account = React.lazy(() => import("./components/myAccount/Account"));
const Layout = React.lazy(() => import("./components/Layout/Layout"));
const ContactUs = React.lazy(() =>
  import("./components/Pages/ContactUs/ContactUs")
);
const Login = React.lazy(() => import("./components/Pages/Login/Login"));
const CouponsOffers = React.lazy(() =>
  import("./components/Pages/CouponsAndOffers/CouponsOffers")
);
const Registration = React.lazy(() =>
  import("./components/Pages/Registration/Registration")
);
const AboutUs = React.lazy(() => import("./components/Pages/AboutUs/AboutUs"));
function App() {
  const baseUrl = config.apiUrl;
  // const [userId, setUserId] = useState("");
  const [userToken, setUserToken] = useState("");
  const token = userToken;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, fetchData, Loading } = useApi();

  useEffect(() => {
    const Url = `${baseUrl}/home/get-coupons-and-offers`;
    const options = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        lang: "ar",
      },
    };
    fetchData(Url, options);
  }, []);
  return (
    <>
      {Loading ? (
        <div
          style={{
            width: "vw",
            height: "vh",
            position: "fixed",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            overflow: "hidden",
          }}
          className="flex justify-center items-center w-screen h-screen z-100   bg-[#fff]"
        >
          <HashLoader color="#36D7B7" loading={Loading} size={150} />
        </div>
      ) : (
        <>
          <div className="custom-scrollbar-container min-h-screen over-flow-x-hidden   ">
            <>
              <Navbar />

              <Routes>
                <Route
                  index
                  element={
                    <React.Suspense>
                      <Home baseUrl={baseUrl} token={token} />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/contact"
                  element={
                    <React.Suspense>
                      <ContactUs />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <React.Suspense>
                      <AboutUs />
                    </React.Suspense>
                  }
                />
                <Route path="/test" element={<Test />} />
                <Route path="/terms-conditions" element={<Terms></Terms>} />
                <Route path="/FAQ" element={<All />} />
                <Route
                  path="/register"
                  element={<Registration baseUrl={baseUrl} />}
                />
                <Route
                  path="/verify/:userId"
                  element={
                    <React.Suspense>
                      <VerifyCode setUserToken={setUserToken} />
                    </React.Suspense>
                  }
                />{" "}
                <Route
                  path="/verify-success"
                  element={
                    <React.Suspense>
                      <SuccessVerify />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/verify-mail/:userId"
                  element={
                    <React.Suspense>
                      <VerifyCodeMail />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/login"
                  element={
                    <React.Suspense>
                      <Login />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/membership"
                  element={
                    <RedirectMohm>
                      <React.Suspense>
                        <Verify />
                      </React.Suspense>
                    </RedirectMohm>
                  }
                />
                <Route
                  path="/medicalnetwork"
                  element={
                    <React.Suspense>
                      <MedicalNetwork />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/joinMedicalNetwork"
                  element={
                    <React.Suspense>
                      <Join />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/forgot-password"
                  element={
                    <React.Suspense>
                      <Forget />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/success"
                  element={
                    <React.Suspense>
                      <SuccessLogin />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <React.Suspense>
                      <ResetPassword />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/couponsoffers"
                  element={
                    <React.Suspense>
                      <CouponsOffers />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/coupon-success"
                  element={
                    <React.Suspense>
                      <CouponSuccess />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/offer-success"
                  element={
                    <React.Suspense>
                      <DiscountOrderSuccess />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/orders"
                  element={
                    <React.Suspense>
                      <Orders />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/login-payment"
                  element={
                    <React.Suspense>
                      <RequestCard />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/payment/success"
                  element={
                    <React.Suspense>
                      <Success />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/payment/failed"
                  element={
                    <React.Suspense>
                      <Fail />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/card-types/:cardId"
                  element={
                    <React.Suspense>
                      <Payment />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/center/:centerId"
                  element={
                    <React.Suspense>
                      <MedicalDetails />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/coupons/:couponId"
                  element={
                    <React.Suspense>
                      <CouponsDetalis />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/account-coupons/:couponId"
                  element={
                    <React.Suspense>
                      <AccountCoupon />
                    </React.Suspense>
                  }
                />
                <Route path="/verfied" element={<Verified />} />
                <Route path="/notfound" element={<NotFound />} />
                <Route
                  path="/account"
                  element={
                    <RedirectMohm>
                      <React.Suspense>
                        <Account baseUrl={baseUrl} />
                      </React.Suspense>
                    </RedirectMohm>
                  }
                />
                {/* <Route path="projects" element={<Projects />} /> */}
              </Routes>
            </>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default App;
