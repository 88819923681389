import React from "react";
import Header from "../../Layout/Header";
import doctor from "../../../assets/images/orders-header.png";
import chield from "./../../../assets/images/order-chield.png";
import OrderSteps from "../../../assets/images/order-steps.png";
import heading from "../../../assets/images/heading.png";
import "./orders.scss";
import PriceList from "./PriceList";

const Orders = () => {
  return (
    <div className="orders-container mb-8">
      <Header name={"طلب بطاقة"} />
      <div className="bg-[#F5FAFE] w-full">
        <div className="get-card p-5 xl:pt-[80px] mt-[-20px] block sm:flex mx-auto w-[370px] sm:w-[600px] md:w-[700px] lg:w-[980px] xl:w-[1200px]">
          <div className="info w-full md:w-[50%]">
            <h1 className="font-bold  text-[#1E2865] text-[30px]">
              احصل على بطاقتك الآن
            </h1>
            <p className="text-[#586475] text-[16px] md:text-[24px] xl:w-[507px]">
              بطاقة مهم تمنحكم خصومات حصرية مقدمة من مراكز طبية وتجميلية ونوادي
              رياضية وغيرها من الخدمات المختلفة في أنحاء المملكة في أكثر من 16
              تخصص طبي وتجميلي.
            </p>
          </div>
          <div className="w-full md:w-[45%] ml-[3%]">
            <img src={doctor} alt="" />
          </div>
        </div>
      </div>
      <div className="w-full bg-white relative flex">
        <div className="how-to-get-card  mx-auto  w-[370px] sm:w-[600px] md:w-[700px] lg:w-[980px] xl:w-[1200px] bg-white   p-10 xl:pb-[60px] ">
          <div className=" flex justify-center   ">
            <div className="text-center ">
              <div className=" mb-[30px] text-center   ">
                {/* <div className="relative  "></div> */}
                <h1 className="text-[#1E2865] xl:w-[500px] mx-auto font-bold relative text-[20px] lg:text-[36px] ">
                  <img
                    className="absolute opacity-[.5] left-[40%] top-[-20px]"
                    src={heading}
                    alt=""
                  />
                  كيف تحصل على البطاقه
                </h1>
              </div>

              <div className=" relative z-[2]  ">
                <img src={OrderSteps} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className=" hidden xl:flex absolute left-[-1%] top-4">
          <img src={chield} alt="" />
        </div>
      </div>

      <PriceList />
      {/* <RequestCard /> */}
    </div>
  );
};

export default Orders;
